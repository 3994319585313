body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
    font-family: PingFang SC;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
  background-color: #fff;
  width: 100%;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

header {
  position: fixed;
  top: 0;
  z-index: 1000;
  width: 100vw;
  height: 4vw;
  line-height: 4vw;
  display: flex;
  justify-content: center;
  transition: box-shadow 0.3s ease-out, background 0.3s ease-out;
}
header.show-shadow {
  background: #FDFDFD;
  box-shadow: 0 2px 8px rgba(229, 229, 229, 0.5);
}
header .headerWrapper {
  position: relative;
  width: 100vw;
  padding: 0 14vw 0 20.1vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header .headerWrapper .logo {
  width: 15.3vw;
  height: 2.6vw;
}
header .headerWrapper .headerBtn {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
header .headerWrapper .headerBtn .button {
  position: relative;
  cursor: pointer;
  color: #fff;
  width: 7vw;
  margin: 0 0.8vw;
  text-align: center;
  border-radius: 1.5vw;
  font-size: 1.3vw;
  line-height: 2.3vw;
  height: 2.3vw;
  border-width: 0;
  font-family: PingFang SC;
  background-color: transparent;
  box-shadow: 0 0 0;
  font-weight: 600;
}
header .headerWrapper .headerBtn .button:hover {
  background: #885FEC;
  color: #fff;
}
header .headerWrapper .headerBtn .active-btn {
  color: #fff;
  border-width: 0;
  font-family: PingFang SC;
  background: #885FEC;
  box-shadow: 0 0 0;
}
header .headerWrapper .headerBtn .active-btn:hover {
  color: #fff;
}
header .btn-white {
  position: relative;
  background-color: #FFFFFF;
  padding: 0 14vw 0 20.1vw;
}
header .btn-white .headerBtn .button {
  color: #885FEC;
}
header .btn-white .active-btn {
  color: #fff !important;
  border-width: 0;
  font-family: PingFang SC;
  background: #885FEC;
  box-shadow: 0 0 0;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 14px;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.features img {
  margin-top: 0 !important;
}
.block {
  position: relative;
}
.banner {
  position: relative;
  margin: auto;
  width: 100vw;
  overflow: hidden;
  background: white url(../../static/media/bannerBg.28d1e621.png) no-repeat;
  background-size: cover;
  background-position: center 0;
  padding-top: 14.5vw;
}
.banner .bgWrapper {
  position: relative;
  margin: 0 auto;
  margin-left: 18.8vw;
  margin-right: 16vw;
  text-align: left;
  display: block;
  padding-bottom: 16vw;
}
.banner .bgWrapper .content {
  position: relative;
  z-index: 1;
}
.banner .bgWrapper .content .title {
  width: 38.2vw;
  height: 5.9vw;
  font-size: 3vw;
  line-height: 3.6vw;
  font-weight: bold;
}
.banner .bgWrapper .content .subtitle {
  display: block;
  width: 34.4vw;
  height: 1.5vw;
  font-size: 1vw;
  margin: 2.6vw 0 0.4vw 0;
  color: #FFFFFF;
}
.banner .bgWrapper .content .desc {
  display: block;
  width: 26.7vw;
  height: 1.5vw;
  font-size: 1vw;
  margin-bottom: 5.2vw;
  color: #FFFFFF;
}
@-webkit-keyframes btn {
  0% {
    opacity: 0.98;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.7;
  }
  60% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
@keyframes btn {
  0% {
    opacity: 0.98;
  }
  40% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.7;
  }
  60% {
    opacity: 0.9;
  }
  100% {
    opacity: 1;
  }
}
.banner .bgWrapper .content .banner-btn {
  width: 18.2vw;
  padding: 0.8vw 1vw 0.7vw 1vw;
  line-height: 1.7vw;
  background-color: #febc42;
  -webkit-animation: btn 5s  linear infinite;
          animation: btn 5s  linear infinite;
  font-size: 1.3vw;
  font-family: PingFang SC;
  font-weight: 600;
  opacity: 1;
  border-radius: 0.5vw;
  border: 0;
}
.banner .bgWrapper .content .banner-btn a {
  color: #FFFFFF;
}
.banner .bgWrapper .content .banner-btn .banner-img {
  width: 2.1vw;
  height: 2.1vw;
}
.banner .bgWrapper .imgWrapper {
  position: absolute;
  z-index: 0;
  right: -3.5vw;
  bottom: 0;
}
.banner .bgWrapper .imgWrapper img {
  width: 42.5vw;
  height: 46.4vw;
}
.banner-ims {
  position: absolute;
  left: -3px;
  top: 0;
  width: 100%;
  height: 150px;
  z-index: 3;
}
.banner-andriod {
  width: 17px;
  height: 20px;
  margin: -5px 10px 0 5px;
}
.banner-newIcon {
  width: 47px;
  height: 47px;
  position: absolute;
  top: -16px;
  right: -8px;
  z-index: 999;
}
.banner-word-box {
  width: 480px;
  height: 400px;
  position: relative;
  display: flex;
  justify-content: flex-start;
}
.banner-word-download {
  width: 275px;
  position: relative;
  height: 110px;
  color: #fff;
  display: flex;
}
.banner-word-download svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-download div {
  position: absolute;
  top: 24px;
  left: 12px;
  z-index: 4;
  color: #fff;
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-word-downloads {
  margin-top: 30px;
  width: 237px;
  position: relative;
  height: 150px;
  color: #fff;
  display: flex;
}
.banner-word-downloads svg {
  width: 17px;
  height: 17px;
  fill: #fff;
  margin-right: 10px;
}
.banner-word-downloads div {
  position: absolute;
  top: 24px;
  left: 20px;
  z-index: 4;
  color: #fff;
  font-family: PingFang SC;
  font-size: 18px;
  font-weight: bold;
  line-height: 21px;
  color: #ffffff;
  opacity: 1;
}
.banner-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.banner-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-text-wrapper {
  width: 40%;
  padding-right: 114px;
  height: 265px;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-text-wrapper h1 {
  font-size: 46px;
}
.banner-text-wrapper p {
  font-size: 16px;
  margin: 50px 0 60px;
  font-weight: 300;
}
.block1 {
  overflow: initial;
  width: 100vw;
  padding-left: 18.1vw;
  padding-right: 18.1vw;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  padding-bottom: 2.6vw;
  padding-top: 1.6vw;
}
.block1 .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.block1 .topTip h2 {
  font-family: PingFang SC;
  font-size: 2.5vw;
  line-height: 3.5vw;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin-bottom: 2vw;
}
.block1 .topTip img {
  width: 22.8vw;
  -webkit-animation: topTipMove 3s linear infinite;
          animation: topTipMove 3s linear infinite;
}
.block1 .topTip div {
  line-height: 1.8vw;
  width: 60.9vw;
  font-family: PingFang SC;
  font-size: 1.1vw;
  font-weight: 600;
  color: #885FEC;
  margin: 1.6vw auto 2.6vw;
}
.block1 .block-main-content {
  z-index: 3;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 0;
}
.block1 .block-main-content .fsTitle {
  padding: 0;
  margin: 0;
  height: 67px;
  font-size: 48px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 67px;
  color: #3b3291;
  opacity: 1;
}
.block1 .block-main-content .fsDesc {
  padding: 0;
  margin: 14px 0 88px 0;
  width: 560px;
  font-size: 29px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 35px;
  color: #5e57a7;
  opacity: 1;
}
.block1 .one-row-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  width: 100%;
  max-width: 1200px;
}
.block1 .one-row-page {
  display: flex;
  justify-content: space-between;
}
.block1 .item-feature {
  text-align: center;
}
.block1 .item-feature .cardInfo {
  width: 13.2vw;
  height: 20.1vw;
  border-radius: 1.6vw;
  color: #333333;
  background: #FFFFFF;
  box-shadow: 0px 2px 3px rgba(136, 100, 233, 0.3);
  padding: 1.6vw 0.7vw 2.6vw;
  position: relative;
}
.block1 .item-feature .cardInfo .cardTitle {
  text-align: center;
  font-size: 1.1vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.2vw;
  opacity: 1;
}
.block1 .item-feature .cardInfo .cardDetail {
  text-align: center;
  font-size: 0.9vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.3vw;
  opacity: 1;
  margin-top: 0.3vw;
}
.block1 .item-feature .cardInfo img {
  position: fixed;
  bottom: 2.6vw;
}
@-webkit-keyframes card {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  15% {
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
  }
  35% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  65% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
}
@keyframes card {
  0% {
    -webkit-transform: scale(0.95);
            transform: scale(0.95);
  }
  15% {
    -webkit-transform: scale(0.97);
            transform: scale(0.97);
  }
  35% {
    -webkit-transform: scale(0.9);
            transform: scale(0.9);
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  65% {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
  }
  100% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
  }
}
.block1 .item-feature .cardInfo .cardIcon1 {
  width: 3.3vw;
  height: 5.7vw;
  left: 4.95vw;
  -webkit-animation: card 3s linear infinite;
          animation: card 3s linear infinite;
}
.block1 .item-feature .cardInfo .cardIcon2 {
  width: 4.5vw;
  height: 5.7vw;
  left: 4.35vw;
  -webkit-animation: card 3s linear infinite;
          animation: card 3s linear infinite;
}
.block1 .item-feature .cardInfo .cardIcon3 {
  width: 3.9vw;
  height: 5.7vw;
  left: 4.65vw;
  -webkit-animation: card 5s linear infinite;
          animation: card 5s linear infinite;
}
.block1 .item-feature .cardInfo .cardIcon4 {
  width: 5.4vw;
  height: 5vw;
  left: 3.9vw;
  -webkit-animation: card 3s linear infinite;
          animation: card 3s linear infinite;
}
.block1 .card {
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  color: #868e96;
  display: inline-block;
  max-width: 360px;
  height: 272px;
  padding: 32px;
  box-shadow: 0 2px 2px rgba(84, 48, 132, 0.06);
  margin: 0 auto;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  text-align: center;
  border-radius: 4px;
}
.block1 .card .card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.block1 .card h3 {
  font-size: 30px;
}
.block1 .card img {
  height: 50px;
  margin: 20px 0;
}
.block1 .card:hover {
  text-decoration: none;
  -webkit-transform: translateY(-12px);
          transform: translateY(-12px);
  box-shadow: 0 12px 24px rgba(84, 48, 132, 0.06);
}
@-webkit-keyframes topTipMove {
  0% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
  }
  20% {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }
  30% {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
  40% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
  90% {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
  }
}
@keyframes topTipMove {
  0% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
  }
  20% {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }
  30% {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
  40% {
    -webkit-transform: translateY(0);
            transform: translateY(0);
  }
  60% {
    -webkit-transform: translateY(2px);
            transform: translateY(2px);
  }
  90% {
    -webkit-transform: translateY(4px);
            transform: translateY(4px);
  }
  100% {
    -webkit-transform: translateY(6px);
            transform: translateY(6px);
  }
}
.block3 {
  margin-top: 0;
  width: 100vw;
  padding: 0 21.6vw;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.block3 .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
  margin-bottom: 1vw;
}
.block3 .topTip .faq {
  width: 6.6vw;
  height: 3.1vw;
  -webkit-animation: topTipMove 3s linear infinite;
          animation: topTipMove 3s linear infinite;
}
.block3 .topTip h2 {
  font-family: PingFang SC;
  font-size: 2.5vw;
  line-height: 3.5vw;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin-bottom: 1vw;
}
.block3 .topTip div {
  line-height: 1.8vw;
  font-family: PingFang SC;
  font-size: 1.1vw;
  height: 1.7vw;
  width: 56.8vw;
  font-weight: 600;
  color: #885FEC;
  text-align: center;
  margin-top: 1.6vw;
}
.block3 .page {
  padding: 0 2.3vw;
}
.block3 .one-card {
  background-color: #F9F7FE;
  opacity: 1;
  border-radius: 1.6vw;
  display: flex;
  align-items: center;
  margin-top: 1.6vw;
}
.block3 .one-card .common-card {
  position: relative;
  width: 100%;
  font-size: 0.9vw;
  padding: 0 0 0 5.2vw;
}
.block3 .one-card .common-card .title {
  font-size: 1.5vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 2.2vw;
  color: #333333;
  margin-bottom: 1vw;
}
.block3 .one-card .common-card .detail {
  font-size: 1vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.5vw;
  color: #333333;
}
.block3 .one-card .common-card .detail p {
  margin-bottom: 0.5vw;
  font-size: 0.9vw;
}
.block3 .one-card .one-img {
  position: relative;
  border-radius: 1.6vw 0 0 1.6vw;
  display: flex;
  justify-content: center;
  align-items: center;
}
.block3 .one-card .one-img img {
  position: relative;
  width: 13.5vw;
  height: 13.5vw;
}
.block3 .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6vw;
}
.block3 .item-feature .cardInfo {
  width: 18.2vw;
  padding-bottom: 2.6vw;
}
.block3 .item-feature .cardInfo .cardTitle {
  font-size: 2vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 3vw;
  opacity: 1;
  color: #2972FB;
}
.block3 .item-feature .cardInfo .cardDetail {
  margin-top: 1vh;
  font-size: 1.5vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 2vw;
  opacity: 1;
}
.block4 {
  min-height: 715px;
  padding: 130px 0;
  background: #F8FAFE;
}
.block4 .slick-slide {
  height: 455px;
}
.block4 .slick-slide .user {
  width: 98px;
  height: 98px;
  border-radius: 100%;
  margin: auto;
}
.block4 .slick-slide .comment {
  line-height: 48px;
  font-size: 20px;
  width: 100%;
  max-width: 680px;
  margin: 41px auto 47px;
  position: relative;
  color: #545454;
  font-weight: 300;
}
.block4 .slick-slide .comment > img {
  position: absolute;
  left: -40px;
  top: -15px;
}
.block4 .slick-slide > h4 {
  font-size: 30px;
  line-height: 38px;
  font-weight: normal;
}
.block4 .slick-slide > p {
  margin-top: 16px;
  font-size: 14px;
  color: #888888;
  font-weight: 300;
}
.block4 .slick-dots li {
  margin: 0 8px;
}
.block4 .slick-dots li button {
  transition: opacity 0.3s;
  width: 10px !important;
  height: 10px !important;
  background: #064852;
  border-radius: 100%;
}
.block4 .slick-dots li button:hover::before,
.block4 .slick-dots li button:focus::before {
  opacity: 1;
  background: #064852;
  width: 20px;
  height: 20px;
}
.block4 .slick-dots li button::before {
  transition: color 0.3s;
}
.block4 .slick-dots li.slick-active button {
  background: #064852;
}
.block4 .slick-dots li.slick-active button::before {
  opacity: 1;
  background: #064852;
}
.block5 {
  min-height: 590px;
}
.block5 h2 {
  margin: 120px auto 25px;
}
.block5 > span {
  margin-bottom: 72px;
}
.block5 .ant-row > div {
  margin-bottom: 20px;
}
.andriod {
  width: 17px;
  height: 18px;
}
.boxBg {
  background: linear-gradient(180deg, #F9F7FE 0%, rgba(255, 255, 255, 0) 100%);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.footer {
  overflow: hidden;
  width: 100vw;
  padding-right: 1vw;
}
.footer .bar {
  background: url(../../static/media/footerBg.62e4c7ec.png) 100% 100% / auto 100% no-repeat;
  opacity: 1;
  padding: 13.7vw 24vw 5.3vw 24vw;
  height: 29vw;
  color: #333333;
  font-weight: 600;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.footer .bar .one-center {
  font-family: PingFang SC;
  font-weight: 600;
  position: relative;
  color: #FFFFFF;
}
.footer .bar .one-center .title {
  padding: 0;
  margin: 0;
  font-size: 1.2vw;
  line-height: 1.2vw;
  margin-bottom: 1.6vw;
}
.footer .bar .one-center .infos {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 1.6vw;
  font-size: 1vw;
}
.footer .bar .one-center .infos .contImg {
  width: 1vw;
  height: 0.8vw;
  margin-right: 0.5vw;
}
.footer .bar .one-center-end {
  text-align: right;
}
.footer .bar .one-center-end .infos {
  text-align: right;
  justify-content: flex-end;
}
.footer .bar .one-center-end .infos a {
  font-size: 1vw;
  color: #FFFFFF;
  text-align: right;
  font-family: PingFang SC;
  font-weight: 600;
}
.footer .bar .one-center-end .infos:last-child {
  margin-bottom: 0;
}
.footer .bottom-bar {
  overflow: hidden;
  text-align: center;
  background: #F8F8F8;
  color: #4D4D4D;
  font-size: 0.7vw;
  line-height: 3.4vw;
  font-family: PingFang SC;
  font-weight: 400;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors */
.text-center {
  text-align: center!important;
}
.text-left {
  text-align: left!important;
}
#reat-content {
  font-family: PingFang SC;
}
.intro .header {
  padding-top: 0;
  padding-left: 0;
  padding-right: 0;
}
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}
.btn {
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.card {
  text-decoration: none;
}
.card .title,
.card .description {
  color: rgba(0, 0, 0, 0.65) !important;
  font-size: 3.7vw;
  font-weight: 300;
}
.card:hover .title {
  color: rgba(0, 0, 0, 0.65) !important;
}
.bodyMob {
  width: 100%;
  overflow-x: hidden;
}
.bodyMob .btn-white {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
}
.banner-mobile {
  position: relative;
}
.block-mobile {
  position: relative;
}
.header-mobile {
  position: relative;
}
.headerWrapper-mobile {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 0 4.5vw !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 13.3vw;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
}
.headerWrapper-mobile .logo {
  width: 34.6vw;
  height: 7.2vw;
}
.headerWrapper-mobile .popover-menu {
  width: 300px;
}
.headerWrapper-mobile .popover-menu button.lang {
  margin: 4.2vw auto;
  float: none;
}
.headerWrapper-mobile .popover-menu div.version {
  margin: 8.5vw auto 4.2vw;
  float: none;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner {
  padding: 0;
  overflow: hidden;
}
.headerWrapper-mobile .popover-menu .ant-popover-inner-content {
  padding: 0;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li span {
  color: #2972FB;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li:hover {
  background: #064852;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul li::selection {
  background: #064852;
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item-selected {
  background: #885fec;
  color: #fff;
}
.headerWrapper-mobile .popover-menu .ant-popover-content .ant-popover-inner ul .ant-menu-item::after {
  border-right: 1vw solid #5b77ee !important;
}
.headerWrapper-mobile .nav-phone-menu {
  fill: #333333;
  width: 5.3vw;
  height: 5.3vw;
  cursor: pointer;
}
.banner-mobile {
  position: relative;
  overflow: hidden;
  background: white url(../../static/media/bannerBg.32f0bb64.png) no-repeat;
  background-size: cover;
  background-position: center 0;
  height: 114.8vw;
  padding-top: 22vw;
  width: 100%;
  overflow-x: hidden;
}
.banner-mobile .bgWrapper {
  position: relative;
  margin: 0 auto;
  text-align: left;
}
.banner-mobile .bgWrapper .content {
  position: relative;
  z-index: 1;
  text-align: center;
}
.banner-mobile .bgWrapper .content .title {
  width: 75.9vw;
  height: 11.7vw;
  display: block;
  margin-left: 8.1vw;
}
.banner-mobile .bgWrapper .content .subtitle {
  display: block;
  margin-left: 8.1vw;
  width: 83vw;
  position: relative;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.6vw;
  font-size: 3.3vw;
  color: #fff;
  text-align: left;
  margin: 4.6vw 0 1.9vw 8.1vw;
}
.banner-mobile .bgWrapper .content .desc {
  width: 85.4vw;
  margin-left: 8.1vw;
  margin-bottom: 9.3vw;
  display: block;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4.5vw;
  font-size: 3.3vw;
  color: #fff;
  text-align: left;
}
.banner-mobile .bgWrapper .content .word-download {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
}
.banner-mobile .bgWrapper .content .banner-btn {
  position: relative;
  text-align: center;
  background: #FEBC42;
  opacity: 1;
  border-radius: 0.9vw;
  font-size: 2.4vw;
  font-family: PingFang SC;
  font-weight: 600;
  padding: 1.8vw 2.8vw 1.9vw 3.6vw;
  margin-left: 8.1vw;
}
.banner-mobile .bgWrapper .content .banner-btn a {
  color: #fff;
}
.banner-mobile .bgWrapper .content .banner-btn .banner-img {
  width: 4.3vw;
  height: 4.3vw;
  margin-left: 0.3vw;
  margin-bottom: 0.7vw;
}
.banner-mobile .bgWrapper .imgWrapper {
  position: relative;
  z-index: 0;
  top: 5.4vw;
  right: -17.8vw;
}
.banner-mobile .bgWrapper .imgWrapper img {
  width: 75.5vw;
  height: 82.4vw;
}
.banner-mobile-ims {
  position: absolute;
  left: -0.8vw;
  top: 0;
  width: 100%;
  height: 40vw;
  z-index: 3;
}
.banner-mobile-andriod {
  width: 4.5vw;
  height: 5.3vw;
  margin: -1.3vw 2.6vw 0 1.3vw;
}
.banner-mobile-newIcon {
  width: 12.5vw;
  height: 12.5vw;
  position: absolute;
  top: -4.2vw;
  right: -2.1vw;
  z-index: 999;
}
.banner-mobile-imgWrapperMobile {
  margin: 0 auto;
  width: 100%;
  height: 100%;
  text-align: center;
}
.banner-mobile-imgWrapperMobile img {
  width: 80%;
  height: 80%;
}
.banner-mobile-img-wrapper {
  width: 60%;
  height: 100%;
}
.banner-mobile-text-wrapper {
  width: 40%;
  padding-right: 13.7vw;
  height: 70.6vw;
  position: absolute;
  margin: auto;
  top: 0;
  bottom: 0;
}
.banner-mobile-text-wrapper h1 {
  font-size: 46px;
}
.banner-mobile-text-wrapper p {
  font-size: 4.2vw;
  margin: 13.3vw 0 16vw;
  font-weight: 300;
}
.block1-mobile {
  overflow: initial;
  width: 100vw;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
  padding-bottom: 5.2vw;
}
.block1-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: center;
}
.block1-mobile .topTip h2 {
  font-family: PingFang SC;
  font-size: 4.4vw;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin: 0;
  margin-top: 2.6vw;
}
.block1-mobile .topTip img {
  width: 44.5vw;
  height: 4.8vw;
}
.block1-mobile .topTip div {
  line-height: 4.4vw;
  width: 84.6vw;
  font-family: PingFang SC;
  font-size: 1.1vw;
  font-weight: 600;
  color: #885FEC;
  margin: 1vw auto 0;
}
.block1-mobile .block-main-content {
  z-index: 3;
  width: 80%;
  max-width: 1200px;
  margin: 0 auto 0;
}
.block1-mobile .block-main-content .fsTitle {
  padding: 0;
  margin: 0;
  height: 67px;
  font-size: 48px;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 67px;
  color: #3b3291;
  opacity: 1;
}
.block1-mobile .block-main-content .fsDesc {
  padding: 0;
  margin: 14px 0 88px 0;
  width: 560px;
  font-size: 29px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 35px;
  color: #5e57a7;
  opacity: 1;
}
.block1-mobile .one-row-block {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 0;
  width: 100%;
  max-width: 1200px;
}
.block1-mobile .one-row-page {
  display: flex;
  flex-wrap: wrap;
  padding: 0 4vw;
}
.block1-mobile .item-feature {
  text-align: center;
  margin: 4.6vw auto 0;
  width: 41.9vw;
  height: 63.6vw;
  background: #FFFFFF;
  box-shadow: 0px 3px 6px rgba(136, 100, 233, 0.3);
  border-radius: 2.8vw;
}
.block1-mobile .item-feature .cardInfo {
  padding-bottom: 2.6vw;
  height: 63.6vw;
  width: 41.9vw;
  color: #333333;
  position: relative;
}
.block1-mobile .item-feature .cardInfo .cardTitle {
  text-align: center;
  font-size: 3.9vw;
  font-family: PingFang SC;
  font-weight: 600;
  opacity: 1;
  margin: 4.6vw 0 2.8vw;
}
.block1-mobile .item-feature .cardInfo .cardDetail {
  text-align: center;
  font-size: 3.3vw;
  font-family: PingFang SC;
  font-weight: 600;
  opacity: 1;
  margin: 0 auto;
  padding: 0 1.5vw;
  line-height: 4.6vw;
}
.block1-mobile .item-feature .cardInfo img {
  position: fixed;
}
.block1-mobile .item-feature .cardInfo .cardIcon1 {
  width: 10.5vw;
  height: 18.1vw;
  bottom: 4.5vw;
  left: 15.7vw;
}
.block1-mobile .item-feature .cardInfo .cardIcon2 {
  width: 14.2vw;
  height: 18.1vw;
  bottom: 4.5vw;
  left: 13.85vw;
}
.block1-mobile .item-feature .cardInfo .cardIcon3 {
  width: 12.5vw;
  height: 18.1vw;
  bottom: 4.6vw;
  left: 14.7vw;
}
.block1-mobile .item-feature .cardInfo .cardIcon4 {
  width: 17.2vw;
  height: 16vw;
  bottom: 5.6vw;
  left: 12.35vw;
}
.block1-mobile .item0 .cardIcon {
  width: 37.8vw;
  height: 29vw;
}
.block1-mobile .item1 .cardIcon {
  width: 32.6vw;
  height: 29vw;
}
.block1-mobile .item2 .cardIcon {
  width: 26.1vw;
  height: 29vw;
}
.block1-mobile .card {
  transition: box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), box-shadow 0.3s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  position: relative;
  color: #868e96;
  display: inline-block;
  max-width: 360px;
  height: 272px;
  padding: 32px;
  box-shadow: 0 2px 2px rgba(84, 48, 132, 0.06);
  margin: 0 auto;
  flex-direction: column;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  text-align: center;
  border-radius: 4px;
}
.block1-mobile .card .card-img-top {
  width: 100%;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.block1-mobile .card h3 {
  font-size: 30px;
}
.block1-mobile .card img {
  height: 50px;
  margin: 20px 0;
}
.block1-mobile .card:hover {
  text-decoration: none;
  -webkit-transform: translateY(-12px);
          transform: translateY(-12px);
  box-shadow: 0 12px 24px rgba(84, 48, 132, 0.06);
}
.block3-mobile {
  margin-top: 0;
  overflow: initial;
  width: 100vw;
  padding: 0 3.7vw;
  padding-bottom: 4.6vw;
  position: relative;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}
.block3-mobile .topTip {
  width: 100%;
  overflow: hidden;
  text-align: left;
  margin-bottom: 2.6vw;
}
.block3-mobile .topTip .faq {
  width: 11.8vw;
  height: 5.5vw;
  display: block;
  margin: 0 auto;
}
.block3-mobile .topTip h2 {
  font-family: PingFang SC;
  font-size: 4.4vw;
  font-weight: 600;
  color: #333333;
  text-align: center;
  margin-bottom: 1vw;
}
.block3-mobile .topTip div {
  font-family: PingFang SC;
  font-size: 3.3vw;
  font-weight: 600;
  color: #885FEC;
  text-align: center;
  line-height: 4.4vw;
  margin-top: 2.8vw;
  padding: 0 6.9vw;
}
.block3-mobile .page {
  padding: 0 2.3vw;
}
.block3-mobile .one-card {
  background-color: #F9F7FE;
  opacity: 1;
  border-radius: 2.8vw;
  display: flex;
  align-items: center;
  margin-top: 2.8vw;
  padding: 3.8vw 0;
}
.block3-mobile .one-card .common-card {
  position: relative;
  width: 100%;
  font-size: 0.9vw;
  padding: 0 0 0 4.6vw;
}
.block3-mobile .one-card .common-card .title {
  font-size: 3.9vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 2.5vw;
  color: #333333;
  margin-bottom: 2vw;
}
.block3-mobile .one-card .common-card .detail {
  font-size: 1vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 1.5vw;
  color: #333333;
  padding-right: 24.1vw;
}
.block3-mobile .one-card .common-card .detail p {
  margin-bottom: 0.5vw;
  font-size: 3.3vw;
  line-height: 4.6vw;
}
.block3-mobile .one-card .one-img {
  position: fixed;
  right: 0;
  bottom: 0;
}
.block3-mobile .one-card .one-img img {
  width: 24.1vw;
  height: 24.1vw;
}
.block3-mobile .item-feature {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 6vw;
}
.block3-mobile .item-feature .cardInfo {
  border-radius: 2.8vw;
  width: 18.2vw;
  padding-bottom: 2.6vw;
}
.block3-mobile .item-feature .cardInfo .cardTitle {
  font-size: 2vw;
  font-family: PingFang SC;
  font-weight: bold;
  line-height: 3vw;
  opacity: 1;
  color: #2972FB;
}
.block3-mobile .item-feature .cardInfo .cardDetail {
  margin-top: 1vh;
  font-size: 1.5vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 2vw;
  opacity: 1;
}
.footer-mobile {
  overflow: hidden;
  background: #FFFFFF;
}
.footer-mobile .one-center {
  font-family: PingFang SC;
  font-weight: 600;
  position: relative;
  text-align: center;
}
.footer-mobile .one-center .title {
  font-size: 4.5vw;
  font-weight: bold;
  line-height: 5.3vw;
  color: #885FEC;
  opacity: 1;
}
.footer-mobile .one-center .infos {
  background: #885FEC;
  opacity: 1;
  border-radius: 1.8vw;
  width: 48vw;
  margin: 0 auto;
  font-size: 3.3vw;
  font-weight: 400;
  line-height: 4.1vw;
  color: #fff;
  margin-top: 2.6vw;
  padding: 1.2vw 0;
}
.footer-mobile .one-center .infos a {
  color: #fff;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 400;
}
.footer-mobile .one-center .infosSelf {
  width: 55vw;
  background: #5B77EE;
  opacity: 1;
  border-radius: 1.8vw;
  margin: 0 auto;
  font-size: 3.3vw;
  font-weight: 400;
  line-height: 4.1vw;
  color: #fff;
  margin-top: 2.6vw;
  padding: 1.2vw 0;
}
.footer-mobile .one-center .infosSelf a {
  color: #fff;
  text-align: center;
  font-family: PingFang SC;
  font-weight: 400;
}
.footer-mobile .one-center-end {
  margin-top: 4.6vw;
  background: white url(../../static/media/footerBg.895ac766.png) 100% 100% / auto 100% no-repeat;
  opacity: 1;
  text-align: left;
  padding: 4.6vw 9vw 6.2vw;
  height: 29vw;
}
.footer-mobile .one-center-end .title {
  font-size: 4vw;
  font-family: PingFang SC;
  font-weight: 600;
  line-height: 4vw;
  color: #333333;
}
.footer-mobile .one-center-end .infos {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 9.5vw;
}
.footer-mobile .one-center-end .infos div {
  line-height: 2.2vw;
  font-size: 3.9vw;
  font-family: PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
}
.footer-mobile .one-center-end .infos .contImg {
  position: relative;
  width: 4.6vw;
  height: 3.7vw;
  margin-right: 0.9vw;
  margin-top: 0.5vw;
}
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: #885fec;
  color: #fff;
}
.ant-menu-item::after {
  border-right: 1vw solid #885fec !important;
}

body {
  background: #f9f7fe;
}
.contentWrapper {
  padding: 20px;
  width: 800px;
  margin: auto;
}
.contentWrapper h2 {
  font-size: 24px;
  text-align: center;
  color: #333;
  font-weight: 500;
}
.contentWrapper p {
  text-align: left;
  text-indent: 30px;
  font-size: 18px;
  line-height: 30px;
  color: #666;
}
@media only screen and (max-width: 830px) {
  .contentWrapper {
    padding: 20px;
    width: 100%;
    margin: auto;
    border: 0;
    box-sizing: border-box;
  }
  .contentWrapper h2 {
    font-size: 24px;
    text-align: center;
    color: #333;
    font-weight: 500;
  }
  .contentWrapper p {
    text-align: left;
    text-indent: 30px;
    font-size: 18px;
    line-height: 30px;
    color: #666;
  }
}

